import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const TDRService = {
  async saveTypeActivity(composant) {
    try {
      const result = await axios.post(`${apiUrl}/TDR/type`, {
        name: composant,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async saveMissionRequest(form,id) {
    try {
      const result = form.id!=='' ?
        await axios.put(`${apiUrl}/TDR/missionrequest/`, form)
        :
        await axios.post(`${apiUrl}/TDR/missionrequest`,form);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async saveMissionActivity(valComposant, raison, id) {
    try {
      //console.log("ato lay donnnes",valComposant,raison,id);
      const result = id ?
        await axios.put(`${apiUrl}/TDR/missioactivity/${id}`, {
          id: id,
          name: valComposant,
          raison: raison,
        })
        :
        await axios.post(`${apiUrl}/TDR/missionactivity`, {
          name: valComposant,
          raison: raison,
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async addComposant() {
    try {
      const result = await axios.get(`${apiUrl}/TDR/composant`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async All(state,activity) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/all?state=`+state+'&activity='+activity);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async Alls(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/alls/${id}`);
      if (result) {
        console.log("ato ny valiny", result.data);
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async pdf(id) {
    try {
      const result = await axios.get(`${apiUrl}/FileTDR/generate/${id}`);
      if (result) {
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async generateTDR(id) {
    try {
      const result = await axios.get(`${apiUrl}/FileTDR/generateTDR/${id}`);
      if (result) {
        return result.data;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async saveFile(filename, id) {
    try {
      const result = await axios.post(`${apiUrl}/FileTDR/addfile`, {
        id: id,
        filename: filename,
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async renderpdf(id) {
    try {
      const result = await axios.get(`${apiUrl}/FileTDR/readFile/${id}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/pdf'
        }
      });
      if (result) {
        return result;

      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async Add(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/add/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async AddTitle(title) {
    try {
      const result = await axios.post(`${apiUrl}/TDRMission/inserttitle`,title);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async updateTitle(title,idtdr) {
    try {
      const result = await axios.post(`${apiUrl}/TDRMission/updatetitle/`+idtdr,title);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  

  async requestID(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/missionrequestByid/`+id);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async listevalidation(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/gethistoryvalidation/`+id);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async delete(id) {
    try {
      const result = await axios.delete(`${apiUrl}/TDR/${id}`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  }
  ,
  async getSuperior(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/${id}`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async updateTDR(id) {
    try {
      const result = await axios.get(`${apiUrl}/TDR/update/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },  
  async getTypeActivity() {
    try {
      const result = await axios.get(`${apiUrl}/TDR/getTypeActivity`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },

  async geTDR() {
    try {
      const result = await axios.get(`${apiUrl}/TDRMission/tdrindex`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },

  async getlistTDR(form) {
    try {
      const result = await axios.post(`${apiUrl}/TDRMission/listTDR`,{
        statut:form.statut,
        mois:form.mois,
        annee:form.annee
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },
  async partage(form) {
    try {
      const result = await axios.post(`${apiUrl}/TDRMission/partage`,form);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }

  },

  async getagendasimple() {
    try {
      const result = await axios.get(`${apiUrl}/Agenda/details`);
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getagenda(form) {
    try {
      const result = await axios.post(`${apiUrl}/Agenda/index`,{
        statut:form.statut,
        mois:form.mois,
        annee:form.annee
      });
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async getagendadelete(id) {
    try {
      const result = await axios.get(`${apiUrl}/Agenda/agendadelete/`+id);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getagendadetails(id) {
    try {
      const result = await axios.get(`${apiUrl}/Agenda/planningdestination/`+id);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async postagenda(id,form,list,type) {
    try {
      const result = await axios.post(`${apiUrl}/PlanningMission/agenda/`+id,
        {
          mission_planning_agenda_details:list,
          tdr:form.tdr_id,
          agenda:form.agenda_id,
          type:type
        }
      );
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async gethistorie(form) {
    try {
      const result = await axios.post(`${apiUrl}/TDRMission/gethistory`,{
        user_id:(form.user_id=="")?form.user_id:(form.user_id).user_id,
        action:form.action.value,
        tdr_id:form.tdr_id
      });
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
}
export default TDRService;