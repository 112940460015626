import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@material-ui/core";
import DownloadIcon from "@mui/icons-material/Download";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Message from "../tools/Message";
import FormLabel from "@mui/material/FormLabel";
import ImmobilisationService from "../../services/ImmobilisationService";

import DirectionService from "../../services/DirectionService";
import FundingService from "../../services/FundingService";
import { Radio, FormControlLabel, RadioGroup } from '@mui/material';
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
export default function ExportExcel(props) {
  const handleChangeDirection = props.handleChangeDirection;
  const handleChangeFinancement = props.handleChangeFinancement;
  const openDialog = props.open;
  const closeDialog = props.closeDialog;
  const [message, setMessage] = React.useState({
    open: false,
    color: "green",
    messageInfo: "",
  });
  const [dataToExport, setDataToExport] = useState([]);
  const [checkedImmo, setcheckedImmo] = useState(true);
  const [checkedExtrat, setcheckedExtrat] = useState(false);
  const [value, setValue] = useState(false);

  const [dir, setDir] = useState([]);
  const [form, setForm] = useState({
    direction_id: 0,
    funding_id: 0,
    service_id: " ",
    type_immo: 0
  });
  const financement_id = props.financement_id;
  const [financement, setFinancement] = useState([]);
  const [direction_id, setIddir] = useState(1);
  const [isShowMessage, setIsShowMessage] = useState(false);

  useEffect(() => {
    if (openDialog) {
      DirectionService.getDirection().then((res) => {
        if (res && res.status) {
          setDir(res.data);
        }
      });
      FundingService.getFunding().then((result) => {
        if (result && result.status) {
          setFinancement(result.data);
          console.log(result.data);
        }
      });
      setForm({
        direction_id: 0,
        funding_id: 0,
        type_immo: 0
      });
      setValue(0);
      setcheckedImmo(true);
      setcheckedExtrat(false);

      console.log("initial status 0: ", checkedImmo);
      console.log("initial status 1: ", checkedExtrat);

      localStorage.setItem("funding", 0);
      
      ImmobilisationService.exportExcel(form.direction_id, form.funding_id).then((result) => {

        if (result.status == true && result.data) {
          setDataToExport(result.data);
        }
      });
    }

  }, [openDialog]);


  useEffect(() => {
    setDataToExport([]);
    setIsShowMessage(false);
    if (value == 1) { //for extrat

      ImmobilisationService.exportExcelExtrat(form.direction_id, form.funding_id).then((result) => {

        if (result.status == true && result.data) {
          setDataToExport(result.data);
        }
      });
    } else { // == 0 for immo
      ImmobilisationService.exportExcel(form.direction_id, form.funding_id).then((result) => {

        if (result.status == true && result.data) {
          setDataToExport(result.data);
        }
      });
    }
  }, [value]);

  const { open, color, messageInfo } = message;
  const [buttonName, setButtonName] = useState("Choisir un fichier");
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  function exportToCSV(dataToExport, fileName, wscols) {
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    wscols[0] = { wpx: 0 };
    ws["!cols"] = wscols;
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataCodeImmo = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataCodeImmo, fileName + fileExtension);
  }
  function handleCloseMessage() {
    setMessage({ ...message, open: false });
  }
  const wscols = [
    {
      wch: 25,
    },
    { wch: 25 },
    { wch: 45 },
    { wch: 15 },
    { wch: 25 },
    { wch: 45 },
  ];

  function handleChange(event) {
    let direction = 0;
    let funding = 0;
    setDataToExport([]);
    setIsShowMessage(false);

    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
    if (name === "direction_id") {
      localStorage.removeItem("direction");

      setIddir(value);
      console.log("direction= ", value);
      localStorage.setItem("direction", value);
    }
    direction = localStorage.getItem("direction");
    if (name === "funding_id") {
      localStorage.removeItem("funding");

      console.log("funding= ", value);
      localStorage.setItem("funding", value);
    }
    funding = localStorage.getItem("funding");

    if (form.type_immo == 0) {
      ImmobilisationService.exportExcel(direction, funding).then((result) => {

        if (result.status == true && result.data) {
          setDataToExport(result.data);
        }
        else{
          setIsShowMessage(true);
        }
      });
    }
    if (form.type_immo == 1){
      ImmobilisationService.exportExcelExtrat(direction, funding).then((result) => {
        console.log(result);
        if (result.status == true && result.data) {
          setDataToExport(result.data);
        }
        else{
          setIsShowMessage(true);
        }
      });
    }
  }

  function handleChangeRadioButton(e) {
    setValue(e.target.value);
    if (e.target.value == 0){
      setForm({
        direction_id: 0,
        funding_id: 0,
        type_immo: 0
      });
    }

    if (e.target.value == 1){
      setForm({
        direction_id: 0,
        funding_id: 0,
        type_immo: 1
      });
    }
  }

  function handleChangeExtrat() {
    setcheckedExtrat(!checkedExtrat);
  }

  return (
    <div>
      <Message
        open={open}
        color={color}
        messageInfo={messageInfo}
        handleClose={handleCloseMessage}
      />
      <Dialog
        open={openDialog}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          id="form-dialog-title"
          color="primary" /*style={{backgroundColor: '#0000B3'}}*/
        >
          {"Exporter les immobilisations"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormLabel component="legend">
                  Export en fichier Excel par direction et/ou par financement
                </FormLabel>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={2}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="gender"
                      name="controlled-radio-buttons-group"
                      value={value}
                      onChange={handleChangeRadioButton}
                    >
                      <FormControlLabel value="0" control={<Radio />} label="Immo" />
                      <FormControlLabel value="1" control={<Radio />} label="Extrat" />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xs={4}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-label">
                      Direction
                      </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Direction"
                      value={form.direction_id}
                      name="direction_id"
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>{"Toutes DIR"}</MenuItem>
                      {dir.map((row) => (
                        <MenuItem value={row.direction_id}>
                          {row.code}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-label">
                      Financement
                      </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="financement"
                      value={form.funding_id}
                      name="funding_id"
                      onChange={handleChange}
                    >
                      <MenuItem value={0}>{"Tous financements"}</MenuItem>
                      {financement.map((row) => (
                        <MenuItem value={row.funding_id}>{row.code}</MenuItem>
                      ))}
                    </Select>
                    <input  type="hidden" name="type_mmo" value={form.type_immo} />
                  </FormControl>
                </Grid>
                
                {isShowMessage &&
                  <Grid item xs={12}>
                      <p style={{color: "orange"}}>Aucune donnée trouvée! Merci de choisir un autre financement.</p>
                  </Grid>
                }
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ backgroundColor: "#6ACFF6" }}
            variant="contained"
            onClick={(e) => exportToCSV(dataToExport, props.fileName, wscols)}
          >
            Exporter
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
