import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const UserService = {
  
  //ADD USER 
  async saveUser(first_name, last_name, telephone, sex_id, email, password, group, name_image, service, userSup, matricule,functionId, id) {
    try {
      const result = id ?
        await axios.put(`${apiUrl}/user/id/${id}`, {
          id,
          first_name,
          last_name,
          telephone,
          sex_id,
          name_image,
          email,
          password,
          group,
          service,
          userSup,
          matricule,
          functionId
        })
        :
        await axios.post(`${apiUrl}/user/`, {
          first_name,
          last_name,
          telephone,
          name_image,
          sex_id,
          email,
          password,
          group,
          service,
          userSup,
          matricule,
          functionId
        });
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  //get user per page
  async getUser(page) {
    try {
      const result = await axios.get(`${apiUrl}/user/${page}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAllUser() {
    try {
      const result = await axios.get(`${apiUrl}/user/`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAllUserActive() {
    try {
      const result = await axios.get(`${apiUrl}/user/getUsersActive`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getAllUserByService(isEditing, service_id, userEdited) {
    try {
      const result = await axios.get(`${apiUrl}/user/getAllUserByService/${isEditing}/${service_id}/${userEdited}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async updateisactive(id) {
    try {
      const result = await axios.put(`${apiUrl}/User/updateisactive/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  //maka anle user par id
  async deleteUser(id) {
    try {
      const result = await axios.delete(`${apiUrl}/user/id/${id}`);
      if (result) {


        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  //maka anle user par id
  async withGroupUser(options) {
    try {
      const result = await axios.get(`${apiUrl}/user/withGroup`, { params: options });
      if (result) {

        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  //maka anle user par id
  async getAllGroup() {
    try {
      const result = await axios.get(`${apiUrl}/group`);
      if (result) {


        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async getSex() {
    try {
      const result = await axios.get(`${apiUrl}/sex`);
      if (result) {


        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,




  //maka anle user par id
  async getUserId(id) {
    try {
      const result = await axios.get(`${apiUrl}/user/id/${id}`);
      if (result) {


        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  async getUserInferior() {
    try {
      const result = await axios.get(`${apiUrl}/user/UserInf/`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async getPlanningFinal(id, idMonth, idParameter) {
    try {
      const result = await axios.get(`${apiUrl}/user/PlanningFinal/${id}/${idMonth}/${idParameter}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async user_info() {
    try {
      const result = await axios.get(`${apiUrl}/user/user_info`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async my_account_update_password(email, confirmPassword) {
    try {
      const result = await axios.put(`${apiUrl}/user/changePasswordPersonnalAccount`, {
        confirmPassword: confirmPassword,
        userEmail: email
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response;
      }
    }
  }
  ,

  async getUserByService(id_service) {
    try {
      const result = await axios.get(`${apiUrl}/user/getUserByService/${id_service}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async getUserSuperiorByUserId(id) {
    try {
      const result = await axios.get(`${apiUrl}/user/getUserSuperiorById/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async getUserSummaryByUserId(id) {
    try {
      const result = await axios.get(`${apiUrl}/user/getUserSummaryByUserId/${id}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,

  async saveUserDetailAnnuaire(user_id, person_id, first_name,
    last_name, flotte, email, interne, service,
    traceability_service_id, function_id, traceability_fonction_id,
    seat, building, porte,name_image) {
    try {
      const result = await axios.put(`${apiUrl}/user/saveUserDetailAnnuaire/`, {
        user_id,
        person_id,
        first_name,
        last_name,
        flotte,
        email,
        interne,
        service,
        traceability_service_id,
        traceability_fonction_id,
        function_id,
        seat,
        building,
        porte,
        name_image
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async getAllUserByDirection(directionId) {
    try {
      const result = await axios.get(`${apiUrl}/user/getAllUserByDirection/${directionId}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async saveUserDelegateAuthority(user_id, idDelegate) {
    try {
      const result = idDelegate ?
        await axios.put(`${apiUrl}/user/updateUserDelegate/${idDelegate}`, {
          user_id
        })
        :
        await axios.post(`${apiUrl}/user/saveUserDelegate`, {
          user_id
        });
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUserDelegateAuthorityByUserId() {
    try {
      const result = await axios.get(`${apiUrl}/user/getAllUserDelegateAuthorityByUserId/`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  
  async deleteUserDelegateAuthority(user_id, id) {
    try {
      const result = await axios.delete(`${apiUrl}/user/deleteUserDelegateAuthority/${id}`,{
        data: user_id
      });
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  


  async checkValidatornumberByUserId(userId) {
    try {
      const result = await axios.get(`${apiUrl}/user/checkValidatornumberByUserId/${userId}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async checkValidatornumberByUserIdMission(userId) {
    try {
      const result = await axios.get(`${apiUrl}/user/checkValidatornumberByUserIdMission/${userId}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },


  async saveValidatorNumber(idValidatorNumber, user_id, validatorNumber, idModule,dayNumber) {
    console.log(idValidatorNumber);
    try {
      const result = idValidatorNumber ?
        await axios.put(`${apiUrl}/user/updateValidatorNumber/${idValidatorNumber}`, {
          user_id,
          validatorNumber,
          dayNumber
        })
        :
        await axios.post(`${apiUrl}/user/saveValidatorNumber`, {
          user_id,
          validatorNumber,
          idModule,
          dayNumber
        });
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async saveValidatorNumberMission(idValidatorNumber, user_id, validatorNumber,idModule) {
    console.log(idValidatorNumber);
    try {
      const result = idValidatorNumber ?
        await axios.put(`${apiUrl}/user/updateValidatorNumberMission/${idValidatorNumber}`, {
          user_id,
          validatorNumber
        })
        :
        await axios.post(`${apiUrl}/user/saveValidatorNumberMission`, {
          user_id,
          validatorNumber,
          idModule
        });
      if (result) {
        // TODO: replace result.data.message by the token
        //localStorage.setItem(ACCESS_TOKEN, result.data.token);
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAllModule() {
    try {
      const result = await axios.get(`${apiUrl}/ModuleType/alls/`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getUserByServiceAndFemalSex(id_dir, id_service) {
    try {
      const result = await axios.get(`${apiUrl}/user/getUserByServiceAndFemalSex/${id_dir}/${id_service}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  
  async getUserSubordonate(id) {
    try {
      const result = await axios.get(`${apiUrl}/rubrique/subordonate/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getAllUserSubordonate(id) {
    try {
      const result = await axios.get(`${apiUrl}/user/getAllUserSubordonate/${id}`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async isUserConnectedDr() {
    try {
      const result = await axios.get(`${apiUrl}/user/isUSerConnectedDr`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
  async isUserConnectedDG() {
    try {
      const result = await axios.get(`${apiUrl}/user/isUserDG`);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  // MAJ CV
  async getCV() {
    try {
      const result = await axios.get(`${apiUrl}/MiseCV/index`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  }
  ,
  
  async issuphierarchie(idsup,iduser) {
    try {
      const result = await axios.post(`${apiUrl}/UserSpecialValidator/suphierarchie`,{
        userSup:idsup,
        user_id:iduser
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },

  async getsuphierarchie(id) {
    try {
      const result = await axios.get(`${apiUrl}/UserSpecialValidator/getsuphierarch/`+id);
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async updatesuphierarchie(idsup,iduser,idbase) {
    try {
      const result = await axios.post(`${apiUrl}/UserSpecialValidator/updatesuphierarchie`,{
        userSup:idsup,
        user_id:iduser,
        superior_id:idbase
      });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async saveValidatorNumberMissionAppui(idValidatorNumber, user_id, validatorNumber,validatorNumberappuiext,idModule) {
    try {
      const result = idValidatorNumber ?
        await axios.put(`${apiUrl}/user/updateValidatorNumberMissionAppui/${idValidatorNumber}`, {
          user_id,
          validatorNumber,
          validatorNumberappuiext
        })
        :
        await axios.post(`${apiUrl}/user/saveValidatorNumberMissionAppui`, {
          user_id,
          validatorNumber,
          idModule,
          validatorNumberappuiext
        });
      if (result) {
        return result.data;
      }
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  async checkValidatornumberByUserIdAppui(userId) {
    try {
      const result = await axios.get(`${apiUrl}/user/checkValidatornumberByUserIdAppui/${userId}`);
      if (result) {
        return result.data;
      }
    }
    catch (error) {
      if (error.response) {
        return error.response.data;
      }
    }
  },
  
}

export default UserService;